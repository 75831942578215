*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  color: inherit;
  height: 0;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: Rubik, sans-serif;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-blur: var(--tw-empty, );
  --tw-brightness: var(--tw-empty, );
  --tw-contrast: var(--tw-empty, );
  --tw-grayscale: var(--tw-empty, );
  --tw-hue-rotate: var(--tw-empty, );
  --tw-invert: var(--tw-empty, );
  --tw-saturate: var(--tw-empty, );
  --tw-sepia: var(--tw-empty, );
  --tw-drop-shadow: var(--tw-empty, );
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  -webkit-print-color-adjust: unset;
  color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 500px) {
  .container {
    max-width: 500px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.-m-3 {
  margin: -.75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.mt-3 {
  margin-top: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-8 {
  height: 2rem;
}

.h-6 {
  height: 1.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-full {
  height: 100%;
}

.h-1\/5 {
  height: 20%;
}

.h-3\/6 {
  height: 50%;
}

.h-3\/5 {
  height: 60%;
}

.h-12 {
  height: 3rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-auto {
  width: auto;
}

.w-6 {
  width: 1.5rem;
}

.w-5 {
  width: 1.25rem;
}

.w-screen {
  width: 100vw;
}

.w-full {
  width: 100%;
}

.w-56 {
  width: 14rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-3\/4 {
  width: 75%;
}

.w-\[9\%\] {
  width: 9%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-28 {
  width: 7rem;
}

.w-2\/5 {
  width: 40%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-1\/2 {
  width: 50%;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-md {
  max-width: 28rem;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: var(--tw-transform);
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: var(--tw-transform);
}

.-translate-y-32 {
  --tw-translate-y: -8rem;
  transform: var(--tw-transform);
}

.-translate-x-4 {
  --tw-translate-x: -1rem;
  transform: var(--tw-transform);
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: var(--tw-transform);
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: var(--tw-transform);
}

.transform {
  transform: var(--tw-transform);
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-des {
  grid-template-rows: min-content 1fr min-content;
}

.flex-col {
  flex-direction: column;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-center {
  justify-items: center;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded {
  border-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-secondary-50 {
  background-color: #00c27d23;
}

.bg-primary-50 {
  background-color: #009cff23;
}

.bg-tertiary-50 {
  background-color: #ffc50023;
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 156, 255, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-bottom {
  vertical-align: bottom;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgba(0, 156, 255, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 125, var(--tw-text-opacity));
}

.text-tertiary {
  --tw-text-opacity: 1;
  color: rgba(255, 197, 0, var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-filter);
}

.filter {
  filter: var(--tw-filter);
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (width >= 536px) {
  #hero-img {
    width: 66.6667%;
    margin-left: auto;
    margin-right: auto;
  }

  .cta {
    grid-template-columns: 2fr 1fr;
  }
}

#description {
  min-height: 100vh;
}

.load {
  animation: 1.2s linear infinite rotate;
}

.founder-2 {
  background-image: linear-gradient(to right, #0000003f, #0000003f), url("manager.aa937d8c.jpg") !important;
}

#founder {
  background-image: linear-gradient(to right, #0000003f, #0000003f), url("founder.b7cdc84c.jpg");
  background-size: cover;
  border-radius: .25rem;
  align-content: flex-end;
  justify-self: center;
  justify-items: center;
  width: 10rem;
  height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

@keyframes carouselAnim {
  from {
    transform: translate(0);
  }

  to {
    transform: translate(calc(1800px - 100%));
  }
}

@media only screen and (width <= 768px) {
  .container .carousel-items {
    animation: 60s linear infinite alternate carouselAnim;
  }

  @keyframes carouselAnim {
    from {
      transform: translate(0);
    }

    to {
      transform: translate(calc(1500px - 100%));
    }
  }
}

.carousel-focus:hover {
  transition: all .8s;
  transform: scale(1.1);
}

#founder h2 {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

#founder h4 {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

#founder + p {
  align-self: center;
}

@media (width >= 640px) {
  #founder + p {
    width: 83.3333%;
  }
}

.btn-nav {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 156, 255, var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
  border-radius: .375rem;
  padding: .75rem 1rem;
  font-size: .75rem;
  line-height: 1rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-nav:hover {
  background-color: #009cffd2;
}

.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 156, 255, var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
  border-radius: .375rem;
  padding: 1rem 1.25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-primary:hover {
  background-color: #009cffd2;
}

.btn-more {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  justify-self: start;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-more:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.btn-tertiary {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
  border-radius: .375rem;
  padding: 1rem 1.25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-tertiary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}

.btn-secondary {
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgba(0, 156, 255, var(--tw-text-opacity));
  background-color: #009cff23;
  border-radius: .375rem;
  padding: 1rem 1.25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-secondary:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
  background-color: #009cffb2;
}

.btn-tertiary-secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .05em;
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
  border-radius: .375rem;
  padding: 1rem 1.25rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-tertiary-secondary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}

.card {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: .5rem;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
}

.icon-box {
  border-radius: .75rem;
  place-self: flex-start start;
  margin-top: .5rem;
  padding: 1rem 1.25rem;
}

.icon-box i {
  fill: currentColor;
}

.icon-box + div h3 {
  margin-bottom: .75rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.icon-box + div h3 + p {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.partners img {
  filter: saturate(0);
  -webkit-user-select: none;
  user-select: none;
  opacity: .75;
}

.sticky-nav {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  opacity: .9;
  width: 1536px;
  position: fixed;
}

@keyframes backdrop-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes backdrop-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.bd-in {
  animation: .3s ease-out backdrop-in;
}

.bd-out {
  animation: .3s ease-in backdrop-out;
}

.modal-enter {
  animation: .3s ease-out modal-in;
}

.modal-out {
  animation: .3s ease-in modal-out;
}

@keyframes modal-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes modal-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166px;
  stroke-width: 2px;
  stroke-miterlimit: 10;
  stroke: #10b981;
  fill: none;
  animation: .6s cubic-bezier(.65, 0, .45, 1) forwards stroke;
}

.checkmark {
  stroke-width: 2px;
  stroke: #fff;
  stroke-miterlimit: 10;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin: 5% auto;
  animation: .4s ease-in-out .4s forwards fill, .3s ease-in-out .9s both scale;
  display: block;
  box-shadow: inset 0 0 #10b981;
}

.checkmark__check {
  transform-origin: 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48px;
  animation: .3s cubic-bezier(.65, 0, .45, 1) .8s forwards stroke;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px #10b981;
  }
}

.crossmark__circle.animateElement {
  stroke-dasharray: 166;
  stroke-dashoffset: 166px;
  stroke-width: 2px;
  stroke-miterlimit: 10;
  stroke: #dc2626;
  fill: none;
  animation: .6s cubic-bezier(.65, 0, .45, 1) forwards stroke;
}

.crossmark.animateElement {
  stroke-width: 2px;
  stroke: #dc2626;
  stroke-miterlimit: 10;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin: 10% auto;
  animation: .4s ease-in-out .4s forwards fillred, .3s ease-in-out .9s both scale;
  display: block;
  box-shadow: inset 0 0 #dc2626;
}

.crossmark__check.animateElement {
  transform-origin: 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48px;
  animation: .3s cubic-bezier(.65, 0, .45, 1) .8s forwards stroke;
}

.cross__path.animateElement {
  stroke: #fff;
  stroke-dasharray: 48;
  stroke-dashoffset: 48px;
  transform-origin: 50% 50% 0;
}

.cross__path--right.animateElement {
  animation: .3s .8s forwards stroke;
}

.cross__path--left.animateElement {
  animation: 1s .8s forwards stroke;
}

@keyframes fillred {
  100% {
    box-shadow: inset 0 0 0 30px #dc2626;
  }
}

.circular-loader {
  transform-origin: center;
  width: 40px;
  height: 40px;
  margin: auto;
  animation: 2s linear infinite rotate;
  position: absolute;
  top: 0;
  left: 0;
}

.loader-path {
  stroke-dasharray: 150 200;
  stroke-dashoffset: -10px;
  stroke-linecap: round;
  animation: 1.5s ease-in-out infinite dash, 6s ease-in-out infinite color;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  0% {
    stroke: #fff;
  }

  40% {
    stroke: #fff;
  }

  66% {
    stroke: #fff;
  }

  80%, 90% {
    stroke: #fff;
  }
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:bg-primary-500:hover {
  background-color: #009cffd2;
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.focus\:border-primary-500:focus {
  border-color: #009cffd2;
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-primary-500:focus {
  --tw-ring-color: #009cffd2;
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-end-3 {
    grid-column-end: 3;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-5\/6 {
    height: 83.3333%;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-11\/12 {
    width: 91.6667%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-items-center {
    justify-items: center;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-2 {
    gap: .5rem;
  }

  .sm\:self-center {
    align-self: center;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:left-1\/2 {
    left: 50%;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:h-3\/5 {
    height: 60%;
  }

  .lg\:h-3\/4 {
    height: 75%;
  }

  .lg\:w-0 {
    width: 0;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: var(--tw-transform);
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:gap-11 {
    gap: 2.75rem;
  }

  .lg\:justify-self-end {
    justify-self: end;
  }

  .lg\:justify-self-center {
    justify-self: center;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

@media (width >= 1280px) {
  .xl\:w-10\/12 {
    width: 83.3333%;
  }

  .xl\:w-9\/12 {
    width: 75%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 1536px) {
  .\32 xl\:h-3\/5 {
    height: 60%;
  }

  .\32 xl\:w-9\/12 {
    width: 75%;
  }

  .\32 xl\:items-stretch {
    align-items: stretch;
  }
}

/*# sourceMappingURL=index.36d28a6b.css.map */
